.w-20 {
  width: 20% !important;
}

.w-80 {
  width: 80% !important;
}

.ant-table-cell {
  font-family: "Roboto", sans-serif;
}
.ant-table-thead > tr > th,
.ant-table-tbody > tr > td,
.ant-table tfoot > tr > th,
.ant-table tfoot > tr > td {
  padding: 0px 0px !important;
  height: 70px;
}

.currency {
  font-size: 17px;
  font-weight: 600;
  color: rgb(41, 39, 39);
}

.money-price {
  font-size: 19px;
  font-weight: 500;
  color: rgb(58, 55, 55);
}

.down {
  animation-name: downAnimation;
  animation-duration: 0.5s;
}

@keyframes downAnimation {
  from {
    color: #292727;
    background-color: white;
  }
  to {
    color: gray;
    background-color: #f3dbdb;
  }
}

.up {
  animation-name: upAnimation;
  animation-duration: 0.5s;
}

@keyframes upAnimation {
  from {
    color: #292727;
    background-color: white;
  }
  to {
    color: gray;
    background-color: #bdf1bd;
  }
}

.ant-table-content {
  background: #f7faff;
  padding: 20px;
}

.panel-header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 80px;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 15px 25px,
    rgba(0, 0, 0, 0.05) 0px 5px 10px;
  margin: 0px;
  padding: 0px;
}

.weather-text {
  display: flex;
  justify-content: start;
  height: 100%;
  flex-direction: column;
  align-self: center;
}

.date-time {
  width: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 0px 20px;
}

.clock {
  font-weight: 600;
  font-size: 24px;
}

.date {
  font-weight: bold;
  font-size: 18px;
}

.panel-table {
  width: 100%;
  border: solid 1px gray;
  background: white;
}
.panel-table-row-data {
  width: 50% !important;
  border-bottom: solid 1px lightgray;
}
.full-page-header {
  margin-bottom: 20px;
}
.curreny-tabpage {
  cursor: pointer;
  width: 50%;
  height: 70px;
  border-bottom: #e0c68a 1px solid;
  text-align: center;
  font-weight: bold;
  font-size: 24px;
  padding-top: 20px;
  box-shadow: rgb(224 198 138 / 33%) 3px 3px 6px 0px inset,
    rgb(255 255 255 / 50%) -3px -3px 6px 1px inset;
}

.curreny-tabpage.active {
  box-shadow: rgba(0, 0, 0, 0.15) 0px 15px 25px,
    rgba(0, 0, 0, 0.05) 0px 5px 10px;
  border-bottom: solid 4px #dbaf49;
  color: #dbaf49;
}
.differance {
  display: flex;
  justify-content: center;
  align-items: center;
}
.differance-logo {
  transform: rotate(180deg);
  width: 21px;
  margin-bottom: 3px;
  margin-right: 2px;
}
@media screen and (max-width: 1024px) {
  .label-column {
    max-width: 40% !important;
  }
  .other-column {
    width: 20%;
  }
  .header-logo {
    width: 65%;
  }
  th,
  td {
    font-size: 14px !important;
  }
  .container {
    width: 100% !important;
  }
  .highest-price {
    display: none;
  }
  .lowest-price {
    display: none;
  }
  .differance-logo {
    width: 18px;
  }
  .curreny-tabpage {
    font-size: 18px;
    height: 50px;
    padding-top: 12px;
  }
}

@media screen and (max-width: 768px) {
  th,
  td {
    font-size: 15px !important;
  }
  .differance-logo {
    width: 16px;
  }
}
