.ant-btn {
    display: flex;
    justify-content: center;
    align-items: center;
}

.ant-popover {
    z-index: 999999;
    box-shadow: 0 4px 24px 10px rgb(34 41 47 / 10%);
}

.ant-popover-buttons {
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.ant-pagination-item-link {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
}

.ant-modal-footer {
    display: flex;
    justify-content: flex-end;
}

.ant-modal-confirm-btns {
    display: flex;
    justify-content: flex-end;
}

.ant-message {
    z-index: 999999;
}

.ant-message-custom-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.ant-list-item-action>li {
    width: 100%;
}

.ant-select-multiple .ant-select-selection-item-remove>.anticon {
    vertical-align: 1px !important;
}

.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
    background-color: #eee;
}

.ant-list-vertical .ant-list-item {
    padding: 10px;
    margin-bottom: 10px;
    border-radius: 10px;
    background-color: #fff;
}

.ant-list-item-action {
    display: flex;
    width: 100%;
}

.ant-steps-item-icon .ant-steps-icon {
    top: -2.5px;
}

.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected .ant-picker-time-panel-cell-inner{
    color: #fff;
}

@media (max-width: 578px) {
    .ant-steps {
        display: inherit;
    }
    .ant-steps-item-title {
        display: none;
    }
    .ant-steps-item-active .ant-steps-item-container .ant-steps-item-content .ant-steps-item-title {
        display: flex;
        width: 70px;
        font-size: 12px;
    }
}