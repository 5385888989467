.currency-main {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  background: #FFFFFF;
}
.currency-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 10vh;
  background-color: #f2f2f2;
  padding: 0px 100px 0px 50px;
  border-bottom: 1px solid #c1c1c188;
}

.currency-weather {
  display: flex;
  align-items: center;
  justify-content: center;
}

.currency-weather-text {
  display: grid;
}

.currency-weather-heat {
  font-weight: 500;
  font-size: 2.5vh;
}

.location {
  font-weight: 500;
  font-size: 1.7vh;
}

.currency-logo > img {
  padding-left: 50px;
  max-height: 6vh;
}

.currency-date {
  display: grid;
  text-align: center;
}

.currency-time {
  font-size: 3vh;
  font-weight: 500;
}

.currency-day {
  font-size: 2.4vh;
  font-weight: 600;
}

.currency-container {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}
.currency-detail {
  display: flex;
  flex-wrap: wrap;
  width: 50%;
}

.currency-cell {
  width: 100%;
  display: flex;
  align-items: center;
  border-width: 1px 0px 1px 1px;
  border-color: #c1c1c188;
  border-style: solid;
  padding: 10px;
  /* min-height: 120px; */
}

.currency-info {
  width: 33%;
  display: flex;
  align-items: center;
}
.currency-info > img {
  height: 6vh;
  margin-right: 15px;
}
.currency {
  display: grid;
}
.currency-title {
  font-size: 3vh;
  font-weight: 700;
  color: #5a5a5a;
}

.currency-label {
  font-size: 3vh;
  font-weight: 700;
  color: #5a5a5a;
}

.currency-status {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 10%;
  text-align: center;
  font-size: 18px;
  font-weight: 500;
}
.currency-status > img {
  width: 3vh;
  transform: rotate(180deg);
}

.currency-variable {
  width: 62%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  font-size: 4.8vh;
  color: #0054a1;
  font-weight: 700;
}

.fullscreen-btn{
  position: absolute;
  right: 0px;
  bottom:0px;
}