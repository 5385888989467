.wave {
    position: fixed;
    bottom: 0;
    left: 0;
    height: 100%;
    z-index: -1;
}

.login-container {
    width: 100vw;
    height: 100vh;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 7rem;
    padding: 0 2rem;
}

.img {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.login-content {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.img img {
    width: 500px;
}

.form {
    width: 100%;
    min-width: 380px;
    max-width: 400px;
    padding: 40px;
    display: flex;
    justify-content: center;
    flex-flow: column;
    box-shadow: 0 3px 10px rgb(0 0 0 / 20%);
    background-color: #fff;
    border-radius: 10px;
}

.login-content img {
    height: 100px;
}

.login-content h2 {
    margin: 15px 0;
    color: #333;
    text-transform: uppercase;
    font-size: 2.9rem;
}

.login-content .input-div {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    width: 100%;
    margin-bottom: 1rem;
    grid-template-columns: 7% 93%;
}

.input-div i {
    font-size: 22px;
    padding-bottom: 5px;
}

.login-content a {
    display: block;
    text-align: right;
    font-size: 0.9rem;
    transition: .3s;
}

@media screen and (max-width: 1050px) {
    .login-container {
        grid-gap: 5rem;
    }
}

@media screen and (max-width: 1000px) {
    .form {
        width: 290px;
    }
    .login-content h2 {
        font-size: 2.4rem;
        margin: 8px 0;
    }
    .img img {
        width: 400px;
    }
}

@media screen and (max-width: 900px) {
    .login-container {
        grid-template-columns: 1fr;
    }
    .img {
        display: none;
    }
    .login-content {
        justify-content: center;
    }
}